<template>
  <div>
    <!-- Task Card Header -->
    <b-card v-if="!isLoading" class="card mb-1" title="Edit Data Guru">
      <!-- Title -->
      <div class="d-flex pb-2">
        <b-avatar
          :src="blobImage"
          :text="avatarText('G')"
          :variant="`light-primary`"
          size="104px"
          rounded
        />
        <div class="ml-1 d-flex flex-column justify-content-center">
          <div class="d-flex mb-1">
            <input
              ref="file"
              accept="image/png, image/jpg, image/jpeg"
              type="file"
              style="display: none"
              @change="onFileChange"
            />
            <b-button
              variant="outline-primary small-gap-right"
              @click="$refs.file.click()"
            >
              Upload Foto Baru
            </b-button>
            <!-- <b-button variant="secondary" @click="blobImage = ''">
              Reset
            </b-button> -->
          </div>
          <span>Upload foto guru format jpg/png maks. 800kb</span>
        </div>
      </div>

      <div class="d-flex justify-content-between">
        <h6 class="mb-0">
          Data Pribadi
        </h6>
      </div>

      <div class="mt-2">
        <b-row>
          <!-- Nama Lengkap -->
          <b-col lg="6" class="mb-1">
            <label class="d-block">Nama Lengkap</label>
            <b-form-input
              v-model="formData.fullname"
              placeholder="Masukkan Nama Lengkap"
            />
          </b-col>

          <!-- Alamat Email -->
          <b-col lg="6" class="mb-1">
            <label class="d-block">Email</label>
            <b-form-input
              v-model="formData.email"
              placeholder="Masukkan Email"
            />
          </b-col>

          <!-- Agama -->
          <!-- <b-col lg="6">
            <label class="d-block">Agama</label>
            <b-form-group>
              <v-select
                v-model="formData.religion"
                label="title"
                :options="religion"
              />
            </b-form-group>
          </b-col> -->

          <!-- Nomor Telepon -->
          <b-col lg="6" class="mb-1">
            <label class="d-block">Nomor Telepon</label>
            <b-form-input
              v-model="formData.phone"
              placeholder="Nomor Telepon"
            />
          </b-col>

          <!-- Tempat Lahir -->
          <!-- <b-col lg="6">
            <label class="d-block">Tempat Lahir</label>
            <b-form-input
              v-model="formData.birthplace"
              class="form-control"
              placeholder="Tempat Lahir"
            />
          </b-col> -->

          <!-- Tanggal Lahir -->
          <!-- <b-col lg="6">
            <label class="d-block">Tanggal Lahir</label>
            <b-form-group>
              <flat-pickr
                v-model="formData.birthdate"
                class="form-control"
                placeholder="Tanggal Lahir"
                :config="{
                  altInput: true,
                  altFormat: 'j F Y',
                  dateFormat: 'Y-m-d',
                }"
              />
            </b-form-group>
          </b-col> -->

          <!-- Alamat -->
          <b-col lg="12" class="mb-1">
            <label class="d-block">Alamat</label>
            <b-form-textarea
              v-model="formData.address"
              placeholder="Alamat"
              rows="3"
              no-resize
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col lg="12">
            <b-button
              variant="primary"
              class="float-right"
              @click="submitEditForm"
            >
              <div class="d-flex align-items-center ">
                <b-spinner
                  v-if="isSaveButtonLoading"
                  class="mr-1"
                  variant="light"
                  small
                />
                Simpan
              </div>
            </b-button>
          </b-col>
        </b-row>
      </div>

      <div class="d-flex justify-content-between">
        <h6 class="mb-0">
          Ganti Password
        </h6>
      </div>

      <div class="mt-2">
        <b-row>
          <!-- Password -->
          <b-col lg="6" class="mb-1">
            <label class="d-block">Password</label>
            <b-input-group class="input-group-merge">
              <b-form-input
                v-model="formData.password"
                :type="passwordFieldType"
                placeholder="Masukkan Password"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIcon"
                  class="cursor-pointer"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
          </b-col>

          <!-- Konfirmasi Password -->
          <b-col lg="6" class="mb-1">
            <label class="d-block">Konfirmasi Password</label>
            <b-input-group class="input-group-merge">
              <b-form-input
                v-model="formData.confirmPassword"
                :type="passwordFieldType"
                placeholder="Masukkan Ulang Password"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIcon"
                  class="cursor-pointer"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col lg="12">
            <b-button
              variant="primary"
              class="float-right"
              @click="submitChangePassword"
            >
              <div class="d-flex align-items-center ">
                <b-spinner
                  v-if="isChangePasswordButtonLoading"
                  class="mr-1"
                  variant="light"
                  small
                />
                Ganti Password
              </div>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <div v-if="isLoading" class="d-flex justify-content-center vertical-center">
      <div class="text-center mt-4">
        <b-spinner variant="primary" class="mr-1" />
        <p class="mt-1">
          Memuat Data Guru
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import client from '@/libs/http/axios-config'

// Components
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BAvatar,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BSpinner,
  BInputGroupAppend,
  BInputGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// Utils
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { checkAuthorizeRole } from '@/auth/utils'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BAvatar,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BSpinner,
    BInputGroupAppend,
    BInputGroup,

    vSelect,
    flatPickr,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      // State
      isLoading: true,
      isChangePasswordButtonLoading: false,
      isSaveButtonLoading: false,
      blobImage: '',

      // Data
      religion: ['Islam', 'Kristen', 'Katolik', 'Hindu', 'Budha', 'Konghucu'],
      userId: this.$router.currentRoute.params.id,

      // Form Error
      formErrors: {
        fullname: { state: null, message: '' },
        email: { state: null, message: '' },
        password: { state: null, message: '' },
        phone: { state: null, message: '' },
        address: { state: null, message: '' },
        avatar: { state: null, message: '' },
      },
      formData: {
        // basic
        fullname: '',
        email: '',
        phone: '',
        address: '',
        avatar: '',

        // extra
        religion: '',
        birthplace: '',
        birthdate: '',

        // change password
        password: '',
        confirmPassword: '',
      },
    }
  },
  computed: {
    passwordFieldType() {
      return this.showPassword ? 'text' : 'password'
    },
    passwordToggleIcon() {
      return this.showPassword ? 'EyeOffIcon' : 'EyeIcon'
    },
  },
  async mounted() {
    await client.get(`/school-admin/users/${this.userId}`).then((response) => {
      this.formData.fullname = response.data.data.name
      this.formData.email = response.data.data.email
      this.formData.phone = response.data.data.phone
      this.formData.address = response.data.data.address
      this.formData.avatar = response.data.data.avatar
      this.blobImage = response.data.data.avatar

      this.isLoading = false
    })
  },
  methods: {
    avatarText,
    onFileChange(e) {
      const file = e.target.files[0]
      this.formData.avatar = file
      this.blobImage = URL.createObjectURL(file)
    },
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      })
    },
    validateForm() {
      const isValid = true
      // adding frontend level validation
      return isValid
    },
    async submitEditForm() {
      this.isSaveButtonLoading = true
      if (this.validateForm()) {
        const data = new FormData()
        data.append('name', this.formData.fullname)
        data.append('email', this.formData.email)
        data.append('password', this.formData.password)
        data.append('phone', this.formData.phone)
        data.append('address', this.formData.address)
        // check if avatar is File Object
        if (typeof this.formData.avatar === 'object') {
          data.append('avatar', this.formData.avatar)
        }
        data.append('_method', 'PUT')

        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }

        // create teacher
        await client
          .post(`school-admin/users/${this.userId}/update`, data, config)
          .then((response) => {
            // stop loading
            this.isSaveButtonLoading = false

            // show success toast
            this.showToast(
              'success',
              'CheckIcon',
              'Berhasil',
              'Guru Berhasil Diubah'
            )

            // redirect to classroom detail
            this.$router.push({
              name: checkAuthorizeRole('teachers-edit'),
              params: { id: this.userId },
            })
          })
          .catch((error) => {
            this.isSaveButtonLoading = false

            console.error(error)

            this.showToast(
              'error',
              'XIcon',
              'Gagal',
              error.response.data.message
            )
          })
      }
    },

    async submitChangePassword() {
      this.isChangePasswordButtonLoading = true
      if (this.validateForm()) {
        const data = new FormData()
        data.append('password', this.formData.password)
        data.append('password_confirmation', this.formData.password)
        data.append('_method', 'PUT')

        // change password
        await client
          .post(`school-admin/users/${this.userId}/change-password`, data)
          .then((response) => {
            // stop loading
            this.isChangePasswordButtonLoading = false

            // show success toast
            this.showToast(
              'success',
              'CheckIcon',
              'Berhasil',
              'Password Berhasil Diubah'
            )

            // redirect to classroom detail
            this.$router.push({
              name: checkAuthorizeRole('teachers-edit'),
              params: { id: this.userId },
            })
          })
          .catch((error) => {
            this.isChangePasswordButtonLoading = false

            this.showToast(
              'error',
              'XIcon',
              'Gagal',
              error.response.data.message
            )
          })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.line-height-small {
  line-height: 1.5rem;
}

.regular-icon {
  top: 0 !important;
  font-size: 1.2rem;
}

.card-icon {
  top: 0 !important;
  font-size: 1.5rem;
}

.card-icon-lg {
  top: 0 !important;
  font-size: 2.5rem;
}

.pointer {
  cursor: pointer;
}

.is-invalid {
  border-color: #ea5455 !important;
}

.w-30 {
  width: 30% !important;
}

.w-70 {
  width: 70% !important;
}
</style>
